import { INTERNAL__useBulkUpdateProblemsImportCandidate } from './client/useBulkUpdateProblemsImportCandidate';
import { INTERNAL__useBulkUpdateProblemsImportImage } from './client/useBulkUpdateProblemsImportImage';
import { INTERNAL__useCreateProblemsImportAttempt } from './client/useCreateProblemsImportAttempt';
import { INTERNAL__useCreateProblemsImportCandidate } from './client/useCreateProblemsImportCandidate';
import { INTERNAL__useDeleteProblemsImportCandidate } from './client/useDeleteProblemsImportCandidate';
import { INTERNAL__useGetProblemsImportCandidateList } from './client/useGetProblemsImportCandidateList';
import { INTERNAL__useGetProblemsImportImageList } from './client/useGetProblemsImportImageList';
import { INTERNAL__useUpdateProblemsImportCandidate } from './client/useUpdateProblemsImportCandidate';
import { INTERNAL__useStartProblemsImportProccess } from './client/useStartProblemsImportProccess';
import { INTERNAL__useGetProblemsImportCandidate } from './client/useGetProblemsImportCandidate';
import { INTERNAL__useUpdateProblemImportImage } from './client/useUpdateProblemImportImage';
import { INTERNAL__useCreateProblemsImportSubjectConfig } from './client/useCreateProblemsImportSubjectConfig';
import { INTERNAL__useUpdateProblemsImportSubjectConfig } from './client/useUpdateProblemsImportSubjectConfig';
import { INTERNAL__useCreateProblemImportCandidateGenerationAttempt } from './client/useCreateProblemImportCandidateGenerationAttempt';
import { INTERNAL__useMapSolutionsFromPdf } from './client/useMapSolutionsFromPdf';
import { INTERNAL__useGetProblemsImportSolutionsMappingConfigList } from '@features/backoffice/problems-import/api/client/useGetProblemsImportSolutionsMappingConfigList';
import { INTERNAL__useGetProblemsImportSolutionMapping } from '@features/backoffice/problems-import/api/client/useGetProblemsImportSolutionMapping';

export const INTERNAL__problemsImportApiClient = {
	useMapSolutionsFromPdf: INTERNAL__useMapSolutionsFromPdf,
	useCreateProblemsImportAttempt: INTERNAL__useCreateProblemsImportAttempt,
	useGetProblemsImportImageList: INTERNAL__useGetProblemsImportImageList,
	useUpdateProblemImportImage: INTERNAL__useUpdateProblemImportImage,
	useBulkUpdateProblemsImportImage: INTERNAL__useBulkUpdateProblemsImportImage,
	useGetProblemsImportCandidateList: INTERNAL__useGetProblemsImportCandidateList,
	useUpdateProblemsImportCandidate: INTERNAL__useUpdateProblemsImportCandidate,
	useBulkUpdateProblemsImportCandidate: INTERNAL__useBulkUpdateProblemsImportCandidate,
	useDeleteProblemsImportCandidate: INTERNAL__useDeleteProblemsImportCandidate,
	useCreateProblemsImportCandidate: INTERNAL__useCreateProblemsImportCandidate,
	useStartProblemsImportProccess: INTERNAL__useStartProblemsImportProccess,
	useGetProblemsImportCandidate: INTERNAL__useGetProblemsImportCandidate,
	useCreateProblemsImportSubjectConfig: INTERNAL__useCreateProblemsImportSubjectConfig,
	useUpdateProblemsImportSubjectConfig: INTERNAL__useUpdateProblemsImportSubjectConfig,
	useCreateProblemImportCandidateGenerationAttempt:
		INTERNAL__useCreateProblemImportCandidateGenerationAttempt,
	useGetProblemsImportSolutionsMappingConfigList: INTERNAL__useGetProblemsImportSolutionsMappingConfigList,
	useGetProblemsImportSolutionMapping: INTERNAL__useGetProblemsImportSolutionMapping,
};
