import type { operations } from '@features/api/schema.d';
import useApiClient from '@features/api/useApiClient';
import { useQuery } from '@tanstack/react-query';
import type { schemas } from '@features/api/schema';

export function INTERNAL__useGetProblemsImportImageList({
	body,
	initialData,
}: {
	body: operations['get_problems_import_image_list']['parameters']['query'];
	initialData: schemas['PaginatedProblemsImportImageList'];
}) {
	const { queries } = useApiClient();

	return useQuery({
		...queries.problemImports.images._ctx.list(body),
		initialData,
	});
}
