import { apiClientFetch } from '@features/api/api.client';
import type { schemas } from '@features/api/schema';
import { useMutation } from '@tanstack/react-query';

export function INTERNAL__useCreateProblemsImportSubjectConfig() {
	return useMutation({
		mutationFn: async (body: schemas['ProblemsImportSubjectConfigCreateRequest']) => {
			const { data, error } = await apiClientFetch.POST(
				'/wt/backoffice/api/problems-import-subject-config/',
				{
					body,
				}
			);

			if (error) {
				throw error;
			}

			return data;
		},
	});
}
