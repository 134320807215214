import { apiClientFetch } from '@features/api/api.client';
import type { schemas } from '@features/api/schema';
import { useMutation } from '@tanstack/react-query';

export function INTERNAL__useUpdateProblemsImportSubjectConfig() {
	return useMutation({
		mutationFn: async (requestData: {
			id: number;
			body: schemas['ProblemsImportSubjectConfigRequest'];
		}) => {
			const { data, error } = await apiClientFetch.PATCH(
				'/wt/backoffice/api/problems-import-subject-config/{id}/',
				{
					params: {
						path: {
							id: requestData.id,
						},
					},
					body: requestData.body,
				}
			);

			if (error) {
				throw error;
			}

			return data;
		},
	});
}
