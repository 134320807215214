import type { schemas } from '@features/api/schema';
import useApiClient from '@features/api/useApiClient';
import { useMutation } from '@tanstack/react-query';
import { $path } from 'next-typesafe-url';
import { useRouter } from 'next/navigation';

export function INTERNAL__useCreateQuizAttempt() {
	const router = useRouter();
	const { apiFetch } = useApiClient();

	return useMutation({
		mutationFn: async (body: schemas['QuizAttemptCreateRequestRequest']) => {
			const { data, error } = await apiFetch.POST('/wt/shared/api/quiz-attempt/', {
				body,
			});

			if (error) throw error;

			return data;
		},
		onSuccess: (respnseData) => {
			router.push(
				$path({
					route: '/frontoffice/quiz/[quizId]/attempt',
					routeParams: { quizId: respnseData.quiz },
				})
			);
		},
	});
}
