import useApiClient from '@features/api/useApiClient';
import { useMutation } from '@tanstack/react-query';

export function INTERNAL__useCreateProblemsImportAttempt({ problemSourceId }: { problemSourceId: number }) {
	const { backofficeApiClient } = useApiClient();

	return useMutation({
		mutationFn: async () => {
			mutationKey: ['useCreateProblemsImportAttempt'];
			const response = await backofficeApiClient.createProblemsImportAttempt({
				problemsImportAttemptCreateRequestRequest: { problem_source: problemSourceId },
			});

			return response.data;
		},
	});
}
