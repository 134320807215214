import type { schemas } from '@features/api/schema';
import useApiClient from '@features/api/useApiClient';
import { useQuery } from '@tanstack/react-query';

export function INTERNAL__useGetActiveQuizAttempt({
	quizId,
	initialData,
}: {
	quizId: number;
	initialData?: schemas['QuizAttempt'];
}) {
	const { queries } = useApiClient();

	return useQuery({
		...queries.quizzes.quiz(quizId)._ctx.activeAttempt,
		initialData: initialData,
		// refetchOnWindowFocus: false,
		// refetchOnMount: false,
		// refetchOnReconnect: false,
	});
}
