import type { schemas } from '@features/api/schema';
import useApiClient from '@features/api/useApiClient';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function INTERNAL__useUpdateQuizAttmept({
	quizId,
	quizAttemptId,
}: {
	quizId: number;
	quizAttemptId: number;
}) {
	const queryClient = useQueryClient();
	const { apiFetch, queries } = useApiClient();
	const queryKey = queries.quizzes.quiz(quizId)._ctx.activeAttempt.queryKey;

	return useMutation({
		mutationFn: async (body: schemas['PatchedQuizAttemptUpdateRequestRequest']) => {
			const { data, error } = await apiFetch.PATCH('/wt/shared/api/quiz-attempt/{id}/', {
				body,
				params: {
					path: {
						id: quizAttemptId,
					},
				},
			});

			if (error) throw error;

			return data;
		},

		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey });
		},
	});
}
