import useApiClient from '@features/api/useApiClient';
import { useQuery } from '@tanstack/react-query';

const retryStatuses = ['FORMATTING', 'FORMATTING_FAILED'];
export function INTERNAL__useGetProblemsImportSolutionMapping({
	problemSourceId,
}: {
	problemSourceId: number;
}) {
	const { queries } = useApiClient();

	return useQuery({
		...queries.problemImports.solutionsMapping._ctx.single({
			problem_source: problemSourceId,
		}),
		refetchInterval(query) {
			if (retryStatuses.includes(query.state.data?.status ?? '')) {
				return 5000;
			}

			return false;
		},
		refetchOnWindowFocus: false,
	});
}
