import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiClient } from '@features/api/api.client';
import useApiClient from '@features/api/useApiClient';
import type { schemas } from '@features/api/schema';

export function INTERNAL__useBulkUpdateProblemsImportImage({ problemSourceId }: { problemSourceId: number }) {
	const queryClient = useQueryClient();
	const { queries } = useApiClient();

	const { mutateAsync: updateProblemsImportImage } = apiClient.problemsImport.useUpdateProblemImportImage({
		problemSourceId,
	});

	return useMutation({
		mutationFn: (reqestData: { id: number; body: schemas['PatchedProblemsImportImageRequest'] }[]) => {
			const promises = reqestData.map((imageRequestData) => {
				return updateProblemsImportImage(imageRequestData);
			});

			return Promise.all(promises || []);
		},
		onSettled: () => {
			queryClient.invalidateQueries({
				queryKey: queries.problemImports.images._ctx.list({ problem_source: problemSourceId })
					.queryKey,
			});
		},
	});
}
