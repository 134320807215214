import type { schemas } from '@features/api/schema';
import useApiClient from '@features/api/useApiClient';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function INTERNAL__useCreateOrUpdateAttemptedAnswer({ quizId }: { quizId: number }) {
	const queryClient = useQueryClient();
	const { apiFetch, queries } = useApiClient();

	const queryKey = queries.quizzes.quiz(quizId)._ctx.activeAttempt.queryKey;
	const queryFn = queries.quizzes.quiz(quizId)._ctx.activeAttempt.queryFn;
	type ActiveAttemptReturnType = Awaited<ReturnType<typeof queryFn>>;

	return useMutation({
		mutationFn: async (body: schemas['QuizProblemAttemptedAnswerRequestRequest']) => {
			const { data, error } = await apiFetch.POST(
				'/wt/shared/api/quiz-problem-attempted-answer/create_or_update/',
				{
					body,
				}
			);

			if (error) throw error;

			return data;
		},
		onMutate: (requestData) => {
			queryClient.cancelQueries({ queryKey });

			const previousData = queryClient.getQueryData<ActiveAttemptReturnType>(queryKey);

			const newAttemptedAnswers = previousData?.quiz_attempt_attempted_answers?.map((answer) => {
				if (answer.question_generic_id === requestData.question_generic_id) {
					return {
						...answer,
						...requestData,
					};
				}

				return answer;
			});

			queryClient.setQueryData<ActiveAttemptReturnType>(queryKey, (old) => {
				if (!old) return undefined;

				return {
					...old,
					quiz_attempt_attempted_answers: newAttemptedAnswers || old.quiz_attempt_attempted_answers,
				};
			});

			return { previousData };
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey });
		},
		onError: (error, variables, context) => {
			queryClient.setQueryData(queryKey, context?.previousData);
		},
	});
}
