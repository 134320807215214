import type { schemas } from '@features/api/schema';
import useApiClient from '@features/api/useApiClient';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { QueryKeyToReturnType } from 'settings/types';

export function INTERNAL__useUpdateProblemImportImage({ problemSourceId }: { problemSourceId: number }) {
	const queryClient = useQueryClient();
	const { queries, apiFetch } = useApiClient();

	const queryKey = queries.problemImports.images._ctx.list({
		problem_source: problemSourceId,
	}).queryKey;

	type ReturnType = QueryKeyToReturnType<typeof queries.problemImports.candidates._ctx.list>;

	return useMutation({
		mutationFn: async ({
			id,
			body,
		}: {
			id: number;
			body: schemas['PatchedProblemsImportImageRequest'];
		}) => {
			const { data, error } = await apiFetch.PATCH('/wt/backoffice/api/problems-import-image/{id}/', {
				params: {
					path: {
						id,
					},
				},
				body,
			});

			if (error) {
				throw error;
			}

			return data;
		},
		onMutate: async (requestData) => {
			await queryClient.cancelQueries({
				queryKey,
			});

			const previousData = await queryClient.getQueryData<ReturnType>(queryKey);

			queryClient.setQueryData<ReturnType>(queryKey, (old) =>
				old
					? {
							...old,
							results: old.results.map((image) =>
								image.id === requestData.id ? { ...image, ...requestData } : image
							),
						}
					: undefined
			);

			return { previousData };
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey });
		},
		onError: (err, attemptImageRequestData, context) => {
			queryClient.setQueryData(queryKey, context?.previousData);
		},
	});
}
