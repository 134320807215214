import type { ApiClientFetch } from '@features/api/api.client';
import type { operations } from '@features/api/schema.d';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const INTERNAL__quizzesQueryConfig = (apiFetch: ApiClientFetch) =>
	createQueryKeys('quizzes', {
		quiz: (quizId: number) => ({
			queryKey: ['quiz', quizId],
			contextQueries: {
				activeAttempt: {
					queryKey: ['active-attempt', quizId],
					queryFn: async () => {
						const { data, error, response } = await apiFetch.GET(
							'/wt/shared/api/quiz-attempt/retrieve-active/',
							{
								params: {
									query: {
										quiz: quizId,
									},
								},
							}
						);

						if (error) throw error;
						if (response.status === 204) return null;

						return data as operations['get_active_quiz_attempt']['responses'][200]['content']['application/json'];
					},
				},
			},
		}),
	});
