import type { schemas } from '@features/api/schema';
import type { operations } from '@features/api/schema.d';
import useApiClient from '@features/api/useApiClient';
import { useQuery } from '@tanstack/react-query';

export function INTERNAL__useGetProblemsImportCandidateList({
	requestData,
	initialData,
}: {
	requestData: operations['get_problems_import_candidate_list']['parameters']['query'];
	initialData?: schemas['PaginatedProblemsImportCandidateList'];
}) {
	const { queries } = useApiClient();

	return useQuery({
		...queries.problemImports.candidates._ctx.list(requestData),
		initialData,
	});
}
