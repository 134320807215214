import { apiClientFetch } from '@features/api/api.client';
import type { schemas } from '@features/api/schema';
import { useMutation } from '@tanstack/react-query';
import { $path } from 'next-typesafe-url';
import { useRouter } from 'next/navigation';

type StartProblemsImportProccessRequest = Omit<
	schemas['UploadProblemsImportFilesRequest'],
	'problem_source_files' | 'solutions_file'
> & {
	problem_source_files: File[] | undefined;
	solutions_file: File | undefined;
};

export function INTERNAL__useStartProblemsImportProccess({ problemSourceId }: { problemSourceId: number }) {
	const router = useRouter();

	return useMutation({
		mutationFn: async (body: StartProblemsImportProccessRequest) => {
			const formData = new FormData();
			if (body.problem_source_files)
				body.problem_source_files.forEach((file) => {
					formData.append('problem_source_files', file);
				});
			if (body.solutions_file) formData.append('solutions_file', body.solutions_file);

			const { data, error } = await apiClientFetch.POST(
				'/wt/backoffice/api/problems-import/{id}/upload_problems_import_files/',
				{
					params: {
						path: {
							id: problemSourceId,
						},
					},
					body: formData as unknown as schemas['UploadProblemsImportFilesRequest'],
				}
			);

			if (error) {
				throw error;
			}

			return data;
		},
		onSuccess: (response, variables) => {
			if (variables.problem_source_files)
				router.push(
					$path({
						route: '/backoffice/problem-sources/[id]/problems-import/editor',
						routeParams: {
							id: problemSourceId,
						},
					})
				);
			else
				router.push(
					$path({
						route: '/backoffice/problem-sources/[id]/problems-import/review',
						routeParams: { id: problemSourceId },
					})
				);
		},
	});
}
