import { apiClientFetch } from '@features/api/api.client';
import type { schemas } from '@features/api/schema';
import useApiClient from '@features/api/useApiClient';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App } from 'antd';

export function INTERNAL__useMapSolutionsFromPdf({ problemSourceId }: { problemSourceId: number }) {
	const { message } = App.useApp();
	const queryClient = useQueryClient();
	const { queries } = useApiClient();

	return useMutation({
		mutationKey: ['problemsImport.mapSolutionsFromPdf'],
		mutationFn: async (body: schemas['MapSolutionsToProblemsRequest']) => {
			const { data, error } = await apiClientFetch.POST(
				'/wt/backoffice/api/problems-import/{id}/map_solutions_to_problems/',
				{
					params: {
						path: {
							id: problemSourceId,
						},
					},
					body,
				}
			);

			if (error) {
				throw error;
			}

			return data;
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: queries.problemImports.candidates._ctx.list({
					problem_source: problemSourceId,
				}).queryKey,
			});
			message.success({
				content: (
					<div>
						<p className="font-bold">Solutions mapped successfully</p>
						<p className="text-sm">Just refresh the page to see the changes</p>
					</div>
				),
				duration: 10,
			});
		},
		onError: (error) => {
			message.error('Something wen wrong');
		},
	});
}
