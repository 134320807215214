import useApiClient from '@features/api/useApiClient';
import { useQuery } from '@tanstack/react-query';

export function INTERNAL__useGetProblemsImportSolutionsMappingConfigList({
	subjectId,
}: {
	subjectId: number;
}) {
	const { queries } = useApiClient();

	return useQuery({
		...queries.problemImports.solutionsMapping._ctx.configList({
			subject: subjectId,
		}),
	});
}
