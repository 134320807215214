import useApiClient from '@features/api/useApiClient';
import { useMutation } from '@tanstack/react-query';
import { useTranslations } from 'next-intl';
import { $path } from 'next-typesafe-url';
import { useRouter } from 'next/navigation';
import { App } from 'antd';

export function INTERNAL__useFinishQuizAttempt({ quizAttemptId }: { quizAttemptId: number }) {
	const { apiFetch } = useApiClient();
	const router = useRouter();
	const { modal } = App.useApp();

	const t = useTranslations('exam');

	const mutation = useMutation({
		mutationFn: async () => {
			const { data, error } = await apiFetch.POST(
				'/wt/shared/api/quiz-attempt/{id}/finish-quiz-attempt/',
				{
					params: {
						path: {
							id: quizAttemptId,
						},
					},
				}
			);

			if (error) throw error;

			return data;
		},

		onSuccess: (responseData) => {
			router.push(
				$path({
					route: '/frontoffice/quiz-attempt/[quizAttemptId]/results',
					routeParams: { quizAttemptId: responseData.id },
				})
			);
		},
	});

	const showConfirmModal = () => {
		modal.confirm({
			title: t('finish_exam_modal_title'),
			content: t('finish_exam_modal_content'),
			onOk: () => mutation.mutate(),
			cancelText: t('back_to_exam_button_label'),
			cancelButtonProps: {
				disabled: mutation.isPending || mutation.isSuccess,
				size: 'small',
				type: 'text',
				className: '!border-2 !border-solid !border-gray-150',
			},
			okText: t('finish_exam_button_label'),
			okButtonProps: {
				size: 'small',
				disabled: mutation.isPending || mutation.isSuccess,
				loading: mutation.isPending || mutation.isSuccess,
			},
			width: 500,
		});
	};

	return { ...mutation, showConfirmModal };
}
