import type { ProblemsImportCandidateRequest } from '@features/api/client';
import useApiClient from '@features/api/useApiClient';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import type { QueryKeyToReturnType } from 'settings/types';

export function INTERNAL__useCreateProblemsImportCandidate({ problemSourceId }: { problemSourceId: number }) {
	const { queries } = useApiClient();
	const queryClient = useQueryClient();
	const { backofficeApiClient } = useApiClient();
	const queryKey = queries.problemImports.candidates._ctx.list({
		problem_source: problemSourceId,
	}).queryKey;

	return useMutation({
		mutationFn: (body: ProblemsImportCandidateRequest) => {
			return backofficeApiClient
				.createProblemsImportCandidate({ problemsImportCandidateRequest: body })
				.then((res) => res.data);
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey });
		},
	});
}
