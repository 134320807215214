import type { BackofficeApiDeleteProblemsImportCandidateRequest } from '@features/api/client';
import useApiClient from '@features/api/useApiClient';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import type { QueryKeyToReturnType } from 'settings/types';

export function INTERNAL__useDeleteProblemsImportCandidate({ problemSourceId }: { problemSourceId: number }) {
	const queryClient = useQueryClient();
	const { queries, backofficeApiClient } = useApiClient();
	const queryKey = queries.problemImports.candidates._ctx.list({
		problem_source: problemSourceId,
	}).queryKey;

	type ReturnType = QueryKeyToReturnType<typeof queries.problemImports.candidates._ctx.list>;

	return useMutation({
		mutationFn: (body: BackofficeApiDeleteProblemsImportCandidateRequest) => {
			return backofficeApiClient.deleteProblemsImportCandidate(body).then((res) => res.data);
		},
		onMutate: async (requestData) => {
			await queryClient.cancelQueries({
				queryKey,
			});

			const previousData = await queryClient.getQueryData<ReturnType>(queryKey);

			queryClient.setQueryData<ReturnType>(queryKey, (old) =>
				old
					? {
							...old,
							count: old.count - 1,
							results: old.results.filter((item) => item.id !== requestData.id),
						}
					: undefined
			);

			return { previousData };
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey });
		},
		onError: (err, requestData, context) => {
			queryClient.setQueryData(queryKey, context?.previousData);
		},
	});
}
