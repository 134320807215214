import { createQueryKeys } from '@lukemorales/query-key-factory';
import { type operations } from '@features/api/schema.d';
import type { ApiClientFetch } from '@features/api/api.client';
export const INTERNAL__problemsImportQueryConfig = (apiFetch: ApiClientFetch) =>
	createQueryKeys('problems-import', {
		images: {
			queryKey: ['images'],
			contextQueries: {
				list: (query: operations['get_problems_import_image_list']['parameters']['query']) => ({
					queryKey: ['list', ...Object.values(query)],
					queryFn: async () => {
						const { data, error } = await apiFetch.GET(
							'/wt/backoffice/api/problems-import-image/',
							{
								params: {
									query,
								},
							}
						);

						if (error) {
							throw error;
						}

						return data;
					},
				}),
				single: (path: operations['get_problems_import_image']['parameters']['path']) => ({
					queryKey: ['single', ...Object.values(path)],
					queryFn: async () => {
						const { data, error } = await apiFetch.GET(
							'/wt/backoffice/api/problems-import-image/{id}/',
							{
								params: {
									path,
								},
							}
						);

						if (error) {
							throw error;
						}

						return data;
					},
				}),
			},
		},
		candidates: {
			queryKey: ['candidates'],
			contextQueries: {
				list: (query: operations['get_problems_import_candidate_list']['parameters']['query']) => ({
					queryKey: ['list', ...Object.values(query)],
					queryFn: async () => {
						const { data, error } = await apiFetch.GET(
							'/wt/backoffice/api/problems-import-candidate/',
							{
								params: {
									query,
								},
							}
						);

						if (error) {
							throw error;
						}

						return data;
					},
				}),
				single: (path: operations['get_problems_import_candidate']['parameters']['path']) => ({
					queryKey: ['single', ...Object.values(path)],
					queryFn: async () => {
						const { data, error } = await apiFetch.GET(
							'/wt/backoffice/api/problems-import-candidate/{id}/',
							{
								params: {
									path,
								},
							}
						);

						if (error) {
							throw error;
						}

						return data;
					},
				}),
			},
		},
		solutionsMapping: {
			queryKey: ['solutions-mapping'],
			contextQueries: {
				single: (
					path: operations['backoffice_api_problems_import_solutions_mapping_retrieve']['parameters']['path']
				) => ({
					queryKey: ['single', ...Object.values(path)],
					queryFn: async () => {
						const { data, error } = await apiFetch.GET(
							'/wt/backoffice/api/problems-import-solutions-mapping/{problem_source}/',
							{
								params: {
									path,
								},
							}
						);

						if (error) {
							throw error;
						}

						return data;
					},
				}),
				configList: (
					query: operations['get_problems_import_solutions_mapping_config']['parameters']['query']
				) => ({
					queryKey: ['config-list', ...Object.values(query ?? {})],
					queryFn: async () => {
						const { data, error } = await apiFetch.GET(
							'/wt/backoffice/api/problems-import-solutions-mapping-config/',
							{
								params: {
									query,
								},
							}
						);

						if (error) {
							throw error;
						}

						return data;
					},
				}),
			},
		},
	});
