import { INTERNAL__useDiscardQuizAttempt } from '@features/frontoffice/quiz/api/client/_useDiscardQuizAttempt';
import { INTERNAL__useCreateOrUpdateAttemptedAnswer } from './client/_useCreateOrUpdateAttemptedAnswer';
import { INTERNAL__useCreateQuizAttempt } from './client/_useCreateQuizAttempt';
import { INTERNAL__useFinishQuizAttempt } from './client/_useFinishQuizAttempt';
import { INTERNAL__useGetActiveQuizAttempt } from './client/_useGetActiveQuizAttempt';
import { INTERNAL__useUpdateQuizAttmept } from './client/_useUpdateQuizAttmept';

export const INTERNAL__quizzesApiClient = {
	useUpdateQuizAttmept: INTERNAL__useUpdateQuizAttmept,
	useCreateOrUpdateAttemptedAnswer: INTERNAL__useCreateOrUpdateAttemptedAnswer,
	useCreateQuizAttempt: INTERNAL__useCreateQuizAttempt,
	useFinishQuizAttempt: INTERNAL__useFinishQuizAttempt,
	useDiscardQuizAttempt: INTERNAL__useDiscardQuizAttempt,
	useGetActiveQuizAttempt: INTERNAL__useGetActiveQuizAttempt,
};
