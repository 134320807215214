import type { BackofficeApiUpdateProblemsImportCandidateRequest } from '@features/api/client';
import useApiClient from '@features/api/useApiClient';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { queryKeys } from 'settings/queryKeys';
import type { QueryKeyToReturnType } from 'settings/types';

export function INTERNAL__useUpdateProblemsImportCandidate({ problemSourceId }: { problemSourceId: number }) {
	const queryClient = useQueryClient();
	const { queries, backofficeApiClient } = useApiClient();

	const queryKey = queries.problemImports.candidates.queryKey;

	return useMutation({
		mutationFn: (body: BackofficeApiUpdateProblemsImportCandidateRequest) => {
			return backofficeApiClient.updateProblemsImportCandidate(body).then((res) => res.data);
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey });
			queryClient.invalidateQueries({ queryKey: queryKeys.problemSourceSection.list(problemSourceId) });
		},
	});
}
